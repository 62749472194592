import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import ContainerHero from '../components/ContainerHero'
import ContainerContent from '../components/ContainerContent'
import ContentProdukteLeistungen from '../components/ContentProdukteLeistungen'
import SEO from '../components/SEO'
import HeroLeftStandard from '../components/HeroLeftStandard'
import HeroCenterStandard from '../components/HeroCenterStandard'
import { graphql, useStaticQuery } from 'gatsby'
import Newsletter from '../components/NewsletterPopup.js'

const Leistungen = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulLeistungen(sort: { order: ASC, fields: order }) {
        edges {
          node {
            contentful_id
            internal{
              type
            }
            beschreibungSatz {
              internal {
                content
              }
            }
            slug
            title
            identifier
            farbe
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Newsletter />
      <SEO
        title="Leistungen"
        description="Unsere Leistungen bauen immer auf den Säulen Kompetenz,
            Zuverlässigkeit und Transparenz auf. Hier finden Sie eine Auswahl
            von verschiedenen Dienstleistungen."
      />
      <Container>
        <ContainerHero>
          <HeroLeftStandard>
            <PageTitle>Leistungen</PageTitle>
          </HeroLeftStandard>
          <HeroCenterStandard>
            Unsere Leistungen bauen immer auf den Säulen Kompetenz,
            Zuverlässigkeit und Transparenz auf. Hier finden Sie eine Auswahl
            von verschiedenen Dienstleistungen.
          </HeroCenterStandard>
        </ContainerHero>
        <ContainerContent>
          <ContentProdukteLeistungen transfer={data.allContentfulLeistungen} />
        </ContainerContent>
      </Container>
    </Layout>
  )
}

export default Leistungen
